.footer-legal {
  &~table{
    font-family: Arial, sans-serif !important;          
    border: none;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    color: #333;
    font-weight: 700;          
    line-height: 18px;
    width: 100%;

    td {
      background-color: #f0f0f0;
      border: 1px solid #000;
      border-collapse: collapse;
      border-spacing: 0;
      padding: 10px;
      text-align: center;
      width: auto;
    }
  }        
}